// =========================================================================================@@
// Last Updated Date: Mar 16, 2023
// Last Updated By: Steven Yuen
// Status Level: 3
// ===========================================================================================

import React, { useContext, useMemo, useRef, useState } from 'react'
import { Formik } from 'formik'
import moment from 'moment'
import { Button, Form, Grid, GridCell, Label, Modal, NotificationInline,
   NotificationManagerContext, Spacer, Text, View } from 'oio-react'
import { Route, useRouteMatch, useHistory } from 'react-router-dom'
import { ArchiveIcon, DeleteIcon } from 'assets/icons'
import { initiativeUrl, initiativeSettingsUrl } from 'config/constants/urls'
import ObjectDeleteConfirm from 'src/sites/kits/Object/components/DeleteConfirm'
import { DateRange, Input, Textarea, Time } from 'src/sites/kits/Utils/ConnectedForm'
import ActionListItemRow from 'src/sites/components/Settings/ActionListItemRow'
import LocationActionListItemRow from 'src/sites/components/Settings/LocationActionListItemRow'
import LocationEdit from 'src/sites/components/Settings/LocationEdit'
import ProtoHeadingBlock from 'src/sites/components/Settings/ProtoHeadingBlock'
import ProtoReturnBar from 'src/sites/components/Settings/ProtoReturnBar'
import { useFileUploads } from 'src/sites/kits/Utils'
import HtmlEditor from 'src/sites/kits/Utils/HtmlEditor'
import ImageUpload from 'src/sites/kits/Utils/ImageUpload'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import { useGlobalState } from 'src/sites/state'
import { useOrganization, useUpdateInitiative } from 'src/core/graphql/hooks'

const InitiativeGeneralSettings = () => {
   const descriptionInputRef = useRef(null)

   const match = useRouteMatch()
   const history = useHistory()
   const { showNotification } = useContext(NotificationManagerContext)
   const [error, setError] = useState(null)
   const [deleteConfirmModalIsOpen, setDeleteConfirmModalIsOpen] = useState(false)
   const [locationModalIsOpen, setLocationModalIsOpen] = useState(true)

   const { organization } = useOrganization()
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { theme } = useGlobalState()
   // const stylesheet = style.stylesheet({ ...organization.typeStyles })

   const initiativeType = organization.initiativeTypes.find(t => t.id === initiative.type.id)
   const defaultInitiativeBody = initiativeType.defaultBody?.replace('\n', '<br />') ?? ''
   // The following will be changed when initiatives use page editor for real
   const initiativeDescription = initiative?.body?.elements?.[0]?.body || defaultInitiativeBody
   const coverMediaUrl = initiative.coverMedia?.file.thumbnailUrlW480

   const { updateInitiative } = useUpdateInitiative()
   const { uploadFile, uploadedFiles, uploadsInProgress, resetFileUploadState } =
      useFileUploads({ targetId: initiative.id, targetType: 'initiative' })

   const handleSaveInitiative = async (values, actions) => {
      setError(null)

      if (uploadsInProgress.length) {
         showNotification({
            message: 'Please wait until your files are done uploading',
            title: 'Error',
            type: 'error'
         })

         actions.setSubmitting(false)
         return
      }

      try {
         const { startDate, startTime, endDate, endTime } = values
         const result = await updateInitiative({ id: initiative.id }, {
            ...uploadedFiles,
            ...values,
            body: [{
               operation: 'updateRichText',
               updateRichText: {
                  id: initiative.body.elements[0].id,
                  body: descriptionInputRef.current.editor.html.get(true)
               }
            }],
            startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
            startTime: startTime ? startTime.format('HH:mm:ss') : null,
            endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
            endTime: endTime ? endTime.format('HH:mm:ss') : null
         })

         resetFileUploadState()
         showNotification({
            message: 'Changes saved successfully',
            title: 'Success!',
            type: 'success'
         })

         if (values.slug !== initiative.slug) {
            history.push(result.data.updateInitiative.gatherUrl)
         }
      } catch (err) {
         setError(err)
      } finally {
         actions.setSubmitting(false)
      }
   }

   // We use `parseZone` to ensure tz offset is preserved and the
   // time isn't converted to local time!!!! LOL!!
   const startDateTimeMoment = useMemo(() => (
      initiative.startDateTimeISO8601
         ? moment.parseZone(initiative.startDateTimeISO8601, moment.ISO_8601)
         : undefined
   ), [initiative.startDateTimeISO8601])

   const endDateTimeMoment = useMemo(() => (
      initiative.endDateTimeISO8601
         ? moment.parseZone(initiative.endDateTimeISO8601, moment.ISO_8601)
         : undefined
   ), [initiative.endDateTimeISO8601])

   return (
      <View>
         <ProtoReturnBar
            display="block[a-c] none[d-f]"
            returnLinkName={`${initiative.type.nameSingular} Settings`}
            returnLinkTo={initiativeSettingsUrl(initiative)}
         />
         <View display="flex" justifyContent="center" width="100%">
            <View
               width="100%"
               maxWidth="100%[a-c] 660px[d-f]"
               padding="20px[a] 25px[b] 30px[c] 40px[d-f]">
               <Formik
                  initialValues={{
                     name: initiative.name,
                     subtitle: initiative.subtitle,
                     slug: initiative.slug,
                     startDate: startDateTimeMoment,
                     startTime: initiative.startTime && startDateTimeMoment,
                     endDate: endDateTimeMoment,
                     endTime: initiative.endTime && endDateTimeMoment
                  }}
                  onSubmit={handleSaveInitiative}
                  render={({ handleSubmit, isSubmitting }) => (
                     <Form
                        elementAppearance="plain"
                        elementBackgroundColor="#eee"
                        elementFocusBackgroundColor="#f3f3f3"
                        onSubmit={handleSubmit}>
                        <View width="100%">
                           <View
                              width="100%"
                              display="flex">
                              <View flex="1 1 auto" paddingRight="30px">
                                 <ProtoHeadingBlock
                                    title={`${initiative.type.nameSingular} Settings`}
                                    description={`General settings about this ${initiative.type.nameSingular}`}
                                 />
                              </View>
                              <View flex="0 0 auto">
                                 <Button
                                    type="submit"
                                    name="Save Changes"
                                    mode={isSubmitting ? 'loading' : 'normal'}
                                    color={theme.protoSettingsHighlightBackgroundColor}
                                    textColor={theme.protoSettingsHighlightForegroundColor}
                                    size="md"
                                    textSize="0.9"
                                    padding="24px"
                                 />
                              </View>
                           </View>
                           <Spacer size="6" />
                           {error && (
                              <NotificationInline
                                 borderRadius="3px"
                                 textSize="2"
                                 type="error"
                                 title="Error"
                                 message={error.graphQLErrors.map(e => e.message).join(', ') || error.message}
                              />
                           )}
                           <View
                              width="100%"
                              padding="18px"
                              borderRadius="3px"
                              backgroundColor="#fff">
                              <Grid spacing="15px">
                                 <GridCell colspan="12">
                                    <Text size="2" weight="medium" color="#333">
                                       {`${initiative.type.nameSingular} name`}
                                    </Text>
                                    <Text size="1.5" color="#999">
                                       (max 100 characters)
                                    </Text>
                                    <Spacer size="2" />
                                    <Input
                                       size="lg"
                                       maxLength="100"
                                       name="name"
                                       style={{
                                          padding: '0 10px',
                                          fontSize: 18,
                                          fontWeight: '500'
                                       }}
                                    />
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Text size="2" weight="medium" color="#333">
                                       Subtitle
                                    </Text>
                                    <Text size="1.5" color="#999">
                                       {`A short sentence describing this ${initiative.type.nameSingular}`}
                                    </Text>
                                    <Spacer size="2" />
                                    <Textarea
                                       rows="2"
                                       size="sm"
                                       name="subtitle"
                                    />
                                 </GridCell>
                                 {initiative.class === 'event' && (
                                    <React.Fragment>
                                       <GridCell colspan="4">
                                          <Text size="2" weight="medium" color="#333">
                                             Date & Time
                                          </Text>
                                          <Text size="1.5" color="#999">
                                             {`Choose when this ${initiative.type.nameSingular} takes place. `}
                                             Date and time fields are both optional.
                                          </Text>
                                       </GridCell>
                                       <GridCell colspan="8">
                                          <Label>Start & End Dates</Label>
                                          <DateRange
                                             startDateName="startDate"
                                             endDateName="endDate"
                                          />
                                          <Grid spacing="12px">
                                             <GridCell colspan="6">
                                                <Label>Start Time</Label>
                                                <Time name="startTime" />
                                             </GridCell>
                                             <GridCell colspan="6">
                                                <Label>End Time</Label>
                                                <Time name="endTime" />
                                             </GridCell>
                                             <GridCell colspan="12">
                                                <Text size="1.5" color="#999">
                                                   Times are set using the
                                                   <b>{` ${initiative.location.timezoneId} `}</b>
                                                   {/* eslint-disable-next-line max-len */}
                                                   timezone. To change the timezone, please use the location editor below.
                                                </Text>
                                             </GridCell>
                                          </Grid>
                                       </GridCell>
                                    </React.Fragment>
                                 )}
                                 <GridCell colspan="12">
                                    <Text size="2" weight="medium" color="#333">
                                       Description
                                    </Text>
                                    <Text size="1.5" color="#999">
                                       {`Description to provide overview about this ${initiative.type.nameSingular}`}
                                    </Text>
                                    <Spacer size="2" />
                                    <HtmlEditor
                                       ref={descriptionInputRef}
                                       config={{ heightMin: 120 }}
                                       initialValue={initiativeDescription}
                                       // stylesheet={stylesheet}
                                    />
                                 </GridCell>
                                 <GridCell colspan="4">
                                    <Text size="2" weight="medium" color="#333">
                                       Cover Image
                                    </Text>
                                    <Text size="1.5" color="#999">
                                       {`Upload an image for this ${initiative.type.nameSingular}. Be sure to click "Save Changes" once your image is uploaded.`}
                                    </Text>
                                 </GridCell>
                                 <GridCell colspan="8">
                                    <View
                                       float="left"
                                       flex="0 0 auto"
                                       width="150px"
                                       borderRadius="3px"
                                       border="1px solid #ccc">
                                       <View
                                          float="left"
                                          width="100%"
                                          height="100%"
                                          borderRadius="3px"
                                          backgroundColor="#fff"
                                          padding="6px">
                                          <ImageUpload
                                             onFileChange={uploadFile}
                                             aspectRatio={0.6}
                                             name="coverMedia"
                                             emptyMessage="No cover image set"
                                             purpose="coverPhoto"
                                             src={coverMediaUrl}
                                          />
                                       </View>
                                    </View>
                                 </GridCell>
                                 <GridCell colspan="12">
                                    <Text size="2" weight="medium">
                                       {`${initiative.type.nameSingular} URL`}
                                    </Text>
                                    <Text size="1.5" color="#999">
                                       {`This ${initiative.type.nameSingular} is accessible through the following URL:`}
                                    </Text>
                                    <Spacer size="2" />
                                    <View
                                       display="flex"
                                       height="30px"
                                       alignItems="center">
                                       <View
                                          flex="0 0 auto"
                                          display="flex"
                                          height="30px"
                                          alignItems="center"
                                          marginRight="12px">
                                          <Text size="1.5" color="#999">
                                             {`domain.com${initiativeUrl(initiative).split('/').slice(0, -1).join('/')}/`}
                                          </Text>
                                       </View>
                                       <View flex="1 1 auto">
                                          <Input size="sm" name="slug" required />
                                       </View>
                                    </View>
                                 </GridCell>
                              </Grid>
                           </View>
                           <Spacer size="8" />
                           <LocationActionListItemRow
                              editUrl={`${match.url}/location`}
                              location={initiative.location}
                           />
                           <Spacer size="8" />
                           <ActionListItemRow
                              name={`${initiative.archived ? 'Unarchive' : 'Archive'} this ${initiative.type.nameSingular}`}
                              description={initiative.archived
                                 ? `This ${initiative.type.nameSingular} is currently archived. It will still be accessible and appear on users' profiles, but it will no longer be featured prominently throughout your site.`
                                 : `Archive this ${initiative.type.nameSingular} if it is no longer used or current. It will still be accessible and appear on users' profiles, but it will no longer be featured prominently throughout your site. This ${initiative.type.nameSingular} can be unarchived later.`
                              }
                              action={(
                                 <Button
                                    onClick={() => handleSaveInitiative({
                                       archived: !initiative.archived
                                    })}
                                    width="90px"
                                    size="xs"
                                    name={initiative.archived ? 'Unarchive' : 'Archive'}
                                    color="#eee"
                                    textColor="#222"
                                 />
                              )}
                              icon={(
                                 <ArchiveIcon
                                    width="18px"
                                    height="18px"
                                    strokeWidth="2px"
                                    color="#aaa"
                                 />
                              )}
                           />
                           <ActionListItemRow
                              name={`Delete this ${initiative.type.nameSingular}`}
                              description={`Permanently delete this ${initiative.type.nameSingular}. This action cannot be undone.`}
                              action={(
                                 <Button
                                    onClick={() => setDeleteConfirmModalIsOpen(true)}
                                    width="90px"
                                    size="xs"
                                    name="Delete"
                                    color="#eee"
                                    textColor="#df4e34"
                                 />
                              )}
                              icon={(
                                 <DeleteIcon
                                    width="18px"
                                    height="18px"
                                    strokeWidth="2px"
                                    color="#aaa"
                                 />
                              )}
                           />
                        </View>
                     </Form>
                  )}
               />
            </View>
         </View>
         <Modal
            borderRadius="6px"
            width="90%[a-b] 300px[c-f]"
            onCloseTrigger={() => setDeleteConfirmModalIsOpen(false)}
            open={deleteConfirmModalIsOpen}
            zIndex="var(--settingsModalLevel2ZIndex)">
            <ObjectDeleteConfirm
               activeSubInitiativeTypeNames={initiative.activeSubInitiativeTypeNames}
               initiativeId={initiative.id}
               initiativeName={initiative.name}
               onCancelButtonClick={() => setDeleteConfirmModalIsOpen(false)}
               // TODO: This is temporary until we figure out a better place to redirect
               // to when initiatives are deleted
               // See PR: #455
               onDelete={() => history.push('/-/profile')}
            />
         </Modal>
         <Route
            path={`${match.url}/location`}
            render={() => (
               <Modal
                  borderRadius="6px"
                  width="100%[a-b] 480px[c-f]"
                  onCloseComplete={() => {
                     history.push(match.url)
                     setLocationModalIsOpen(true)
                  }}
                  onCloseTrigger={() => setLocationModalIsOpen(false)}
                  open={locationModalIsOpen}
                  zIndex="var(--settingsModalLevel2ZIndex)">
                  <LocationEdit
                     targetType="initiative"
                     onCloseButtonClick={() => setLocationModalIsOpen(false)}
                  />
               </Modal>
            )}
         />
      </View>
   )
}

export default InitiativeGeneralSettings
