import React from 'react'
import PropTypes from 'prop-types'
// import GatherLayout from 'src/sites/custom/gather/components/Layout'
import { useOrganization } from 'src/core/graphql/hooks'

const GatherLayout = React.lazy(() => import('src/sites/custom/gather/components/Layout'))

const SplashLayout = ({ children }) => {
   const { organization } = useOrganization()

   if (organization.slug === 'gather') {
      return (
         <React.Suspense fallback={null}>
            <GatherLayout>{children}</GatherLayout>
         </React.Suspense>
      )
   }

   return children
}

SplashLayout.propTypes = {
   children: PropTypes.node.isRequired
}

export default SplashLayout
