// Global Layouts
// import layoutBox from './layoutBox'
import layoutHeader from './layoutHeader'
import layoutSidebar from './layoutSidebar'
import layoutSplash from './layoutSplash'

// Colour Palettes
import paletteContrast from './paletteContrast'
import paletteLight from './paletteLight'

// Custom
import gns3 from './gns3'

const defaults = {
   // Body - Text Style
   tmpBodyTextSize: '2.5',
   // Form Style
   formStyle: {
      elementAppearance: 'plain',
      elementBackgroundColor: '#f3f3f3',
      elementFocusBackgroundColor: '#eee',
      labelTextColor: '#000',
      labelTextSize: '2.5',
      labelTextTransform: 'none',
      labelTextWeight: 'medium'
   },

   // Note: These settings colours constants are highly subject to change
   // Use with care see PR #328
   protoSettingsHighlightBackgroundColor: '#342e39',
   protoSettingsHighlightForegroundColor: '#99cbff',
   viewportContentMaxWidth: '1700px',
   viewportMarginLeft: '0px',
   viewportMarginRight: '0px',
   viewportPaddingBottom: '0px[a] 2vw[b] 2vw[c] 3vh[d] 3vh[e] 2vw[f]',
   viewportPaddingLeft: '20px[a] 20px[b] 40px[c] 30px[d] 5vw[e] 150px[f]',
   viewportPaddingRight: '20px[a] 20px[b] 40px[c] 30px[d] 5vw[e] 150px[f]',
   viewportPaddingTop: '30px[a] 7vw[b] 5vw[c] 6vh[d] 8vh[e] 3vw[f]',

   // Unsorted Props - to be deprecated
   tmpToolbarHeight: '65px'
}

export default {
   _defaults: {
      ...defaults,
      ...layoutHeader,
      ...paletteLight
   },
   'banff-pipeline-workshop': {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   'teacher-education-and-certification-committee': {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   'edmonton-area-field-experiences-committee-eafec': {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   lafec: {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   eyeq: {
      ...defaults,
      ...layoutHeader,
      ...paletteContrast
   },
   // TODO: Bring this back when Fuse can properly use bleeding edge
   // PR #701
   fuse: {
      ...defaults,
      ...layoutHeader,
      ...paletteContrast
   },
   gather: {
      ...defaults,
      ...layoutSplash,
      ...paletteLight,
      hideFooter: true
   },
   gns3: {
      ...defaults,
      ...layoutHeader,
      ...paletteContrast,
      ...gns3
   },
   'mother-co': {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   'mother-website': {
      ...defaults,
      ...layoutSplash,
      ...paletteLight
   },
   kinfolk: {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   'national-geographic': {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   nike: {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   'ajay-sabhaney': {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   'steven-yuen': {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   },
   'tool-terminal': {
      ...defaults,
      ...layoutSplash,
      ...paletteLight
   },
   'walking-together': {
      ...defaults,
      ...layoutSidebar,
      ...paletteLight
   }
}
