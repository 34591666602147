import React, { useContext, useMemo } from 'react'
import { Formik } from 'formik'
import { Button, Form, Text, View } from 'oio-react'
import PropTypes from 'prop-types'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import FormView from 'src/sites/kits/UI/Form/View'
import { unwindProductMap } from '../utils'

const JoinRegistrationForm = ({
   currentStepNumber,
   initialRegistrationFormValues,
   onGoBack,
   onContinue,
   numTotalSteps,
   selectedProducts,
   useBatchRegistration
}) => {
   const { initiative } = useContext(InitiativeHierarchyContext)

   // eslint-disable-next-line camelcase
   let formElements = initiative?.apps?.registration_form?.elements || []
   if (!useBatchRegistration) {
      formElements = formElements.map(element => ({
         ...element,
         id: `forms[0].${element.id}`
      }))
   }

   const forms = useMemo(() => {
      if (useBatchRegistration && selectedProducts) {
         return unwindProductMap(selectedProducts).map((product, i) => ({
            product,
            productFormElements: [
               {
                  id: `forms[${i}].userEmail`,
                  label: 'Attendee Email Address',
                  multiline: false,
                  required: true,
                  type: 'text'
               },
               {
                  id: `forms[${i}].userFirstName`,
                  label: 'Attendee First Name',
                  multiline: false,
                  required: true,
                  type: 'text'
               },
               {
                  id: `forms[${i}].userLastName`,
                  label: 'Attendee Last Name',
                  multiline: false,
                  required: true,
                  type: 'text'
               },
               ...formElements.map(element => ({
                  ...element,
                  id: `forms[${i}].${element.id}`
               }))
            ]
         }))
      }

      return null
   }, [selectedProducts, useBatchRegistration])

   return (
      <Formik
         initialValues={initialRegistrationFormValues}
         onSubmit={onContinue}
         render={({ handleSubmit, isSubmitting, values }) => (
            <Form
               elementAppearance="plain"
               elementBackgroundColor="#eee"
               elementFocusBackgroundColor="#f3f3f3"
               onSubmit={handleSubmit}>
               <View
                  position="absolute"
                  top="0px"
                  left="0px"
                  width="100%"
                  height="100%"
                  scroll="on[a-b]">
                  <View
                     width="100%"
                     height="calc(100% - 72px)[c-f]"
                     scroll="on[c-f]">
                     <View
                        display="flex"
                        alignItems="center"
                        width="100%"
                        minHeight="90px"
                        padding="24px 18px[a] 24px 30px[b-f]"
                        borderBottom="1px solid #eee">
                        <View>
                           <Text size="4" weight="medium">
                              {initiative.name}
                           </Text>
                           <Text size="2" color="#888">
                              {`Step ${currentStepNumber} of ${numTotalSteps} `}
                              &mdash;
                              Fill in the following:
                           </Text>
                        </View>
                     </View>
                     <View
                        width="100%"
                        padding="18px 18px 0px 18px[a] 18px 30px 0px 30px[b-f]">
                        {forms && forms.map(({ product, productFormElements }, i) => (
                           // eslint-disable-next-line react/no-array-index-key
                           <React.Fragment key={`${product.id}-${i}`}>
                              <View
                                 padding="10px"
                                 borderRadius="3px"
                                 backgroundColor="#35b865">
                                 <Text size="2" weight="medium" color="#fff">
                                    {`Ticket ${i + 1} - ${product.name}`}
                                 </Text>
                              </View>
                              <FormView elements={productFormElements} />
                           </React.Fragment>
                        ))}
                        {!forms && (
                           <FormView elements={formElements} />
                        )}
                     </View>
                  </View>
                  <View
                     display="flex"
                     justifyContent="flex-end"
                     alignItems="center"
                     position="relative[a-b] absolute[c-f]"
                     bottom="0px[c-f]"
                     left="0px[c-f]"
                     width="100%"
                     height="72px"
                     padding="0px 18px[a] 0px 24px[b-f]"
                     borderTop="1px solid #eee"
                     style={{ gap: '4px' }}>
                     <Button
                        onClick={() => onGoBack(values)}
                        color="#eee"
                        textColor="#444"
                        size="md"
                        mode={isSubmitting ? 'disabled' : 'normal'}
                        name="Previous"
                     />
                     <Button
                        type="submit"
                        size="md"
                        color="#35b865"
                        mode={isSubmitting ? 'loading' : 'normal'}
                        name={currentStepNumber === numTotalSteps
                           ? 'Register'
                           : 'Continue'
                        }
                     />
                  </View>
               </View>
            </Form>
         )}
      />
   )
}

JoinRegistrationForm.propTypes = {
   currentStepNumber: PropTypes.number.isRequired,
   initialRegistrationFormValues: PropTypes.object,
   onGoBack: PropTypes.func.isRequired,
   onContinue: PropTypes.func.isRequired,
   numTotalSteps: PropTypes.number.isRequired,
   selectedProducts: PropTypes.instanceOf(Map),
   useBatchRegistration: PropTypes.bool.isRequired
}

JoinRegistrationForm.defaultProps = {
   initialRegistrationFormValues: {},
   selectedProducts: undefined
}

export default JoinRegistrationForm
