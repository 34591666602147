// =========================================================================================@@
// Last Updated Date: Mar 24, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React from 'react'
import { Helmet } from 'react-helmet'
import { View } from 'oio-react'
import { organizationsWithCover } from 'config/constants/themeDefaults/layoutBox'
import { homeEditUrl } from 'config/constants/urls'
import { useOrganization } from 'src/core/graphql/hooks'
import HomeEdit from 'src/sites/kits/Home/apps/Edit'
import HomeMoreButton from 'src/sites/kits/Home/components/MoreButton'
import HomeLayoutSidebar from 'src/sites/kits/Home/components/LayoutSidebar'
import HomeLayoutSafe from 'src/sites/kits/Home/components/LayoutSafe'
import HomeLayoutTmpDefault from 'src/sites/kits/Home/components/LayoutTmpDefault'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import EyeQHomeLayout from 'src/sites/custom/eyeq/Home'
import FuseHomeLayout from 'src/sites/custom/fuse/Home'
import GatherHome from 'src/sites/custom/gather/Home'
import GNS3HomeLayout from 'src/sites/custom/gns3/Home/Layout'
import MotherWebsiteHomeLayout from 'src/sites/custom/mother-website/Home'
import { useGlobalState } from 'src/sites/state'

const defaultLayouts = {
   box: HomeLayoutSafe,
   community: HomeLayoutSafe,
   header: HomeLayoutTmpDefault,
   sidebar: HomeLayoutSidebar
}

// Splash Layouts require custom Home Layout
const customLayouts = {
   // 'abc-company': HomeLayoutSafe,
   // kinfolk: HomeLayoutSafe,
   // nike: HomeLayoutSafe,
   // 'banff-pipeline-workshop': HomeLayoutSafe,
   eyeq: EyeQHomeLayout,
   fuse: FuseHomeLayout,
   gather: GatherHome,
   gns3: GNS3HomeLayout,
   // 'mother-co': HomeLayoutSafe,
   'mother-website': MotherWebsiteHomeLayout
}

const Home = () => {
   const { theme } = useGlobalState()
   const currentLayout = theme.tmpGlobalLayout

   const { organization } = useOrganization()
   const { currentUserCanEdit, name } = organization
   const hasHomeCover = organizationsWithCover.includes(organization.slug)

   const defaultLayout = defaultLayouts[currentLayout]
   const Layout = customLayouts[organization.slug] || defaultLayout

   return (
      <>
         <Helmet title={name} />
         {!hasHomeCover && <Layout />}
         {currentUserCanEdit && (
            <View
               position="fixed"
               bottom="30px"
               right="30px"
               zIndex="2">
               <HomeMoreButton />
            </View>
         )}
         <ModalRoute path={homeEditUrl}>
            {({ open, onCloseComplete, onCloseTrigger }) => (
               <HomeEdit
                  modalOnCloseComplete={onCloseComplete}
                  modalOnCloseTrigger={onCloseTrigger}
                  modalOpen={open}
                  onCloseButtonClick={onCloseTrigger}
                  onUpdate={onCloseTrigger}
               />
            )}
         </ModalRoute>
      </>
   )
}

export default Home
