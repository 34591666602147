import React from 'react'

const Footer = () => {
   return (
      <div className="flex flex-col sm:grid grid-cols-12 gap-12 border-t border-solid border-neutral-200 my-12 py-8 uppercase">
         <div className="w-full col-span-12 md:col-span-8 text-xs text-neutral-500">
            <img
               src="/assets/custom/gather/images/mother-logo.png"
               className="h-6 mb-4"
               alt="Gather"
            />
            &copy; 2024 Gather by Mother Company
            <br />
            Independent studio founded in 2009
         </div>
         <div className="col-span-6 md:col-span-2 text-xs text-neutral-500">
            <div className="font-semibold mb-2">Location</div>
            Suite 506 174 Spadina Avenue
            <br />
            Toronto, Canada
            <br />
            M5T 2C2
         </div>
         <div className="col-span-6 md:col-span-2 text-xs text-neutral-500">
            <div className="font-semibold mb-2">Connect</div>
            <a rel="noreferrer noopenner" href="mailto:team@gather.co">
               Email
            </a>
            <br />
            <a rel="noreferrer noopenner" href="http://twitter.com/gather_do" target="_blank">
               Twitter
            </a>
            <br />
            <a rel="noreferrer noopenner" href="http://instagram.com/gather_do" target="_blank">
               Instagram
            </a>
            <br />
            <a rel="noreferrer noopenner" href="https://www.threads.net/@gather_do" target="_blank">
               Threads
            </a>
         </div>
      </div>
   )
}

export default Footer
