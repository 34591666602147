import PropTypes from 'prop-types'
import React from 'react'

const ArrowIcon = ({
   className,
   color,
   width,
   height,
   strokeWidth
}) => (
   <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      fill="none"
      width={width}
      height={height}>
      <g
         className={className}
         stroke={color}
         strokeLinecap="round"
         strokeLinejoin="round"
         strokeWidth={strokeWidth}>
         <line x1="2" y1="12" x2="22" y2="12" />
         <polyline points="15,5 22,12 15,19 " />
      </g>
   </svg>
)

ArrowIcon.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   height: PropTypes.string,
   strokeWidth: PropTypes.string,
   width: PropTypes.string
}

ArrowIcon.defaultProps = {
   className: undefined,
   color: '#444',
   height: '16px',
   strokeWidth: '2',
   width: '16px'
}

export default React.memo(ArrowIcon)
