import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const CoverVideo = ({ className, url }) => {
   return (
      <div
         className={classNames(className)}
         style={{
            position: 'relative',
            margin: '0px',
            width: '100%',
            borderRadius: '6px',
            backgroundSize: 'cover',
            overflow: 'hidden',
            background: '#000'
         }}>
         <div
            style={{
               position: 'absolute',
               left: '50%',
               top: '0',
               width: '2000px',
               marginLeft: '-1000px',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               height: '100%'
            }}>
            <video className="h-full" height="100%" preload="none" autoPlay muted loop>
               <source src={`${url}.mp4`} type="video/mp4" />
               <source src={`${url}.webm`} type="video/webm" />
               Your browser does not support the video
            </video>
         </div>
      </div>
   )
}

CoverVideo.propTypes = {
   className: PropTypes,
   url: PropTypes.string.isRequired
}

CoverVideo.defaultProps = {
   className: ''
}

export default CoverVideo
