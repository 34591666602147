import React from 'react'
import { Link } from 'react-router-dom'
import ArrowIcon from '../icons/arrow'

const EarlyAccessButton = () => {
   return (
      <Link
         to="/earlyaccess"
         className="group flex items-center justify-center w-48 h-12 text-xs weight-semibold uppercase border-2 border-solid border-black gap-2 tracking-wide">
         <span className="group-hover:-translate-x-2 transition-transform duration-300">Early Access</span>
         <span className="group-hover:translate-x-2 transition-transform duration-300">
            <ArrowIcon width="16" height="16" color="var(--titleTextColor)" />
         </span>
      </Link>
   )
}

export default EarlyAccessButton
