import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { get } from 'lodash-es'
import FileInput from 'react-files'
import { Button } from 'oio-react'
import uuidv4 from 'src/sites/kits/Utils/uuidv4'
import InitiativeDefaultThumbnail from 'src/sites/kits/Initiative/components/DefaultThumbnail'

const ConnectedFileInput = ({
   accepts = ['*/*'],
   multiple = false,
   name,
   required = false,
   formik
}) => {
   const fieldId = React.useRef(uuidv4())
   const [error, setError] = useState()
   const [files, setFiles] = useState(
      get(formik.values, name)
         ? get(formik.values, name)
         : []
   )

   useEffect(() => {
      // Force the underlying html component to reset so that we can properly enforce `required`
      if (files.length === 0) {
         const $fileInput = document.getElementById(`reg-form-${fieldId.current}`)
         if ($fileInput) {
            $fileInput.value = ''
         }
      }

      formik.setFieldValue(name, files)
   }, [name, files])

   const handleFilesAdd = (newFiles) => {
      if (multiple) {
         setFiles(currentFiles => [...currentFiles, ...newFiles])
      } else {
         setFiles(newFiles)
      }
   }

   return (
      <>
         {error && (
            <div>{`Error: ${error.message}`}</div>
         )}
         <FileInput
            clickable
            disabled
            accepts={accepts}
            dragActiveClassName={css`
               background: #eff5f6;
            `}
            onChange={handleFilesAdd}
            onError={setError}
            multiple={multiple}
            name={name}
            maxFileSize={50000000} // 50MB
            minFileSize={0}
            // style={fileInputStyle}
            inputProps={{
               id: `reg-form-${fieldId.current}`,
               required: required ? files.length === 0 : false,
               style: { position: 'absolute', opacity: 0 }
            }}>
            {(files.length === 0 || multiple) && (
               <div
                  style={{
                     border: '1px dashed #ccc',
                     padding: '12px 18px',
                     fontSize: '13px'
                  }}>
                  Drop a file or click here to upload.
               </div>
            )}
         </FileInput>
         {files.map((file, i) => (
            <div
               key={file.id}
               style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '6px 0',
                  fontSize: '13px',
                  borderBottom: '1px solid #eee'
               }}>
               <div
                  style={{
                     width: '36px',
                     height: '36px',
                     borderRadius: '4px',
                     overflow: 'hidden',
                     marginRight: '16px'
                  }}>
                  <InitiativeDefaultThumbnail
                     highlightColor="#e5e5e5"
                     iconName="document"
                     iconSize="12px"
                  />
               </div>
               <div style={{ flex: '1 1 auto' }}>
                  <b>{file.name}</b>
                  <br />
                  <span style={{ opacity: 0.5 }}>
                     {file.sizeReadable}
                  </span>
               </div>
               <Button
                  width="78px"
                  onClick={() => {
                     setFiles(currentFiles => ([
                        ...currentFiles.slice(0, i),
                        ...currentFiles.slice(i + 1)
                     ]))
                  }}
                  size="xs"
                  name="Remove"
                  color="#eee"
                  textColor="#333"
               />
            </div>
         ))}
      </>
   )
}

ConnectedFileInput.propTypes = {
   formik: PropTypes.object.isRequired,
   accepts: PropTypes.arrayOf(PropTypes.string),
   multiple: PropTypes.bool,
   name: PropTypes.string.isRequired,
   required: PropTypes.bool
}

// const withRef = WrappedComponent => React.forwardRef((props, ref) => (
//    <WrappedComponent {...props} forwardedRef={ref} />
// ))

export default connect(ConnectedFileInput)
