// =========================================================================================@@
// Last Updated Date: Mar 3, 2023
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import { LAYOUT_BOX, LAYOUT_COMMUNITY, LAYOUT_HEADER, LAYOUT_SIDEBAR, LAYOUT_SPLASH } from 'config/constants/ui'
import gns3Theme from 'config/constants/themeDefaults/gns3'
import layoutBoxConstants from 'config/constants/themeDefaults/layoutBox'
import layoutCommunityConstants from 'config/constants/themeDefaults/layoutCommunity'
import layoutHeaderConstants from 'config/constants/themeDefaults/layoutHeader'
import layoutSidebarConstants from 'config/constants/themeDefaults/layoutSidebar'
import layoutSplashConstants from 'config/constants/themeDefaults/layoutSplash'
import motherWebsiteTheme from 'config/constants/themeDefaults/motherWebsite'
import { useOrganization } from 'src/core/graphql/hooks'
import { useGlobalState } from 'src/sites/state'
import HUDMenu from '~/components/HUDMenu'
import BoxLayout from '~/components/Layout/Box'
import CommunityLayout from '~/components/Layout/Community'
import HeaderLayout from '~/components/Layout/Header'
import SidebarLayout from '~/components/Layout/Sidebar'
import SplashLayout from '~/components/Layout/Splash'

const customThemes = {
   gns3: gns3Theme,
   'mother-website': motherWebsiteTheme
}

const layouts = {
   [LAYOUT_BOX]: {
      component: BoxLayout,
      constants: layoutBoxConstants
   },
   [LAYOUT_COMMUNITY]: {
      component: CommunityLayout,
      constants: layoutCommunityConstants
   },
   [LAYOUT_HEADER]: {
      component: HeaderLayout,
      constants: layoutHeaderConstants
   },
   [LAYOUT_SIDEBAR]: {
      component: SidebarLayout,
      constants: layoutSidebarConstants
   },
   [LAYOUT_SPLASH]: {
      component: SplashLayout,
      constants: layoutSplashConstants
   }
}

const GlobalLayout = ({ children }) => {
   const location = useLocation()
   const { theme, setTheme } = useGlobalState()
   const currentLayout = theme.tmpGlobalLayout
   const isSidebar = currentLayout === 'sidebar'
   const showColumnView = theme.showColumnView || false
   const LayoutComponent = layouts[currentLayout].component
   const userAgent = window.navigator.userAgent.toLowerCase()
   const isElectron = userAgent.includes(' electron/')

   const { organization } = useOrganization()
   const { highlightColor } = organization.theme
   const highlightColorRgbObj = tinycolor(highlightColor || '#18181b').toRgb()
   const cssVarSafeHighlightColor = `${highlightColorRgbObj.r}, ${highlightColorRgbObj.g}, ${highlightColorRgbObj.b}`

   const isBleedingEdge = organization?.gatherAppVersion === 6 ||
      process.env.NODE_ENV === 'development'

   // Set theme constants for current layout
   useEffect(() => {
      const customTheme = customThemes[organization.slug] || {}

      setTheme({
         ...theme,
         ...layouts[currentLayout].constants,
         ...customTheme
      })

      document.body.className = `gather-layout-${currentLayout} theme-${organization.slug} ${showColumnView && isSidebar ? 'showColumnView' : ''}`
      document.documentElement.style.setProperty('--highlightColorVar', cssVarSafeHighlightColor)
   }, [currentLayout, organization.slug, showColumnView])

   useEffect(() => {
      document.body.style.background = 'var(--bodyBackgroundColor)'
   }, [])

   // TODO: Change how this works
   // For the following routes, render without Layout Container
   // as they are standalone views or takeover the screen
   // PR #757
   if (location.pathname.startsWith('/account') ||
      location.pathname.startsWith('/-/invitation') ||
      location.pathname.startsWith('/-/start')) {
      return children
   }

   return (
      <>
         {isElectron && <div className="gather-viewport-titlebar" />}
         <LayoutComponent>
            {children}
         </LayoutComponent>
         {isBleedingEdge && <HUDMenu />}
         {/* <div className="grid">
            <div className="column" />
            <div className="column" />
            <div className="column" />
            <div className="column" />
            <div className="column" />
            <div className="column" />
            <div className="column" />
            <div className="column" />
            <div className="column" />
            <div className="column" />
         </div>
         <div className="baseline-grid" /> */}
      </>
   )
}

GlobalLayout.propTypes = {
   children: PropTypes.node.isRequired
}

export default GlobalLayout
